import React from "react";
import "./banner.css"

function Banner() {
    return (
        <>
            <div className="banner">
                <span className="banner-text">Limited May Promotion:
                    <a className="whatsapp-link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/+60124710418?text=I%20want%20to%20grab%20limited%20promo">Grab Now</a>
                    !</span>
            </div>
        </>
    );
}

export default Banner;