import React from 'react';
import './App.css';
import Banner from './components/Banner/banner';
import Hero from './components/hero/hero';
import Product from './components/Product/product';
/* import Ingredients from './components/Ingredients/ingredients'; */
import FreeGift from './components/FreeGift/freeGift';
import Testi from './components/Testi/testi';
import BackToTop from './components/BackToTop/backToTop';
import { SpeedInsights } from "@vercel/speed-insights/react"
import LoadExternalScript from './ExternalScript';

function App() {

  return (
    <>
      <LoadExternalScript />
      <Banner />
      <Hero />
      <Product />
      {/* <Ingredients /> */}
      <FreeGift />
      <Testi />
      <BackToTop />
      <SpeedInsights />
    </>
  );
}

export default App;
