import { useEffect } from 'react';

const LoadExternalScript = () => {
    useEffect(() => {
        // Check if the script already exists
        if (window.fbqc) return;

        (function (f, b, e, v, n, t, s) {
            if (f.fbqc) return;
            n = f.fbqc = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbqc) f._fbqc = n;
            n.push = n;
            n.loaded = !0;
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            "script",
            "https://cs-api.hicomm.app/js/clid.js?t=1718793957710&btnImgWidth=100&btnPosY=80&btnPosX=5&phone=61899322089&wnid=341"
        );
    }, []); // Empty dependency array means this effect runs once after the initial render

    return null;
};

export default LoadExternalScript;