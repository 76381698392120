import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './hero.css';
import './heroMedia.css';
import '../../App.css'

function Hero() {
  const [heroTextAnimation, setHeroTextAnimation] = useSpring(() => ({
    from: { transform: 'translateY(100%)' },
    to: { transform: 'translateY(0)' },
    config: { mass: 1, tension: 280, friction: 55 },
  }));

  const [heroImageAnimation, setHeroImageAnimation] = useSpring(() => ({
    from: { transform: 'translateY(50%)' },
    to: { transform: 'translateY(0)' },
    config: { mass: 1, tension: 280, friction: 55 },
  }));

  useEffect(() => {
    setHeroTextAnimation({ from: { transform: 'translateY(100%)' }, to: { transform: 'translateY(0)' } });
    setHeroImageAnimation({ from: { transform: 'translateY(50%)' }, to: { transform: 'translateY(0)' } });
  });

  const handleCta = () => {
    window.hiCommMessengerInstance.triggerHiCommMessenger();
  };

  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-content">
          <animated.div className="hero-text" style={heroTextAnimation}>
            <div className='wlc-msg'>
              <h1 className="zenso-gradient">Welcome to ZENSO</h1>
            </div>
            <p>
              Join our 30-day weight management program to reduce body weight,
              body fat, and visceral fat. Start your journey towards a healthier, more vibrant you today.
            </p>
            <button className="cta-btn" onClick={handleCta}>Get Started</button>
          </animated.div>
          <animated.div className="hero-image" style={heroImageAnimation}>
            <img src="/assets/hero-img1.png" alt="Hero" />
          </animated.div>
        </div>
      </div>
    </section>
  );
}

export default Hero;