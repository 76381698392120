import React from 'react';
import { useMediaQuery } from 'react-responsive';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './testi.css';
import './testiMedia.css';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

export default function Testi() {
    const mobileScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const padScreen = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1200px)' });

    const commonSwiperProps = {
        cssMode: true,
        navigation: true,
        pagination: { clickable: true },
        mousewheel: true,
        keyboard: true,
        modules: [Navigation, Pagination, Mousewheel, Keyboard],
    };

    const resultSwiperProps = { ...commonSwiperProps };
    const testiSwiperProps = { ...commonSwiperProps };

    if (padScreen) {
        testiSwiperProps.slidesPerView = 2;
        testiSwiperProps.spaceBetween = 0;
    } else if (!mobileScreen) {
        testiSwiperProps.slidesPerView = 3;
        testiSwiperProps.spaceBetween = 1;
    }

    return (
        <>
            <div className='testi-section'>
                <div className='testi-text'>
                    <div className='section-title'>
                        <span>CUSTOMER TESTIMONIALS</span>
                    </div>
                    <div className='section-header'>
                        <span>Proven Results from Our Customers</span>
                    </div>
                    <div className='section-subheader'>
                        <span>In just 30 days with ZENSO: pants become loose, waists get smaller, weight drops within a week, and body fat decreases significantly.</span>
                    </div>
                </div>
                <Swiper
                    {...resultSwiperProps}
                    className="mySwiper"
                    id='results-swiper'
                >
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti1.jpg" alt="Result 1" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti2.jpg" alt="Result 2" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti3.jpg" alt="Result 3" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti4.jpg" alt="Result 4" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti5.jpg" alt="Result 5" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti6.jpg" alt="Result 6" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti7.jpg" alt="Result 7" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti8.jpg" alt="Result 8" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti9.jpg" alt="Result 9" />
                    </SwiperSlide>
                    <SwiperSlide id='results-slide'>
                        <img src="/assets/AusTesti10.jpg" alt="Result 10" />
                    </SwiperSlide>
                </Swiper>
                <Swiper
                    {...testiSwiperProps}
                    className="mySwiper"
                    id="testiSwiper"
                >
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat1.jpg" alt="chat 1" />
                    </SwiperSlide>
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat2.jpg" alt="chat 2" />
                    </SwiperSlide>
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat3.jpg" alt="chat 3" />
                    </SwiperSlide>
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat4.jpg" alt="chat 4" />
                    </SwiperSlide>
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat5.jpg" alt="chat 5" />
                    </SwiperSlide>
                    <SwiperSlide id='testi-slide'>
                        <img src="/assets/chat6.jpg" alt="chat 6" />
                    </SwiperSlide>
                </Swiper>
            </div >
        </>
    );
}
