import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { IoGiftOutline } from "react-icons/io5";
import './freeGift.css';
import './freeGiftMedia.css';

function FreeGift() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,  // Adjust as needed
  });

  const freeSectionStyle = useSpring({
    transform: inView ? 'translateY(0)' : 'translateY(50%)',
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 280, friction: 55 },
    delay: inView ? 0 : 0,
  });

  useEffect(() => {
    console.log(inView ? 'In view, starting animation' : 'Not in view');
  }, [inView]);

  const handleCta = () => {
    window.hiCommMessengerInstance.triggerHiCommMessenger();
  };

  return (
    <section className="free-section">
      <div className="container">
        <div ref={ref} className="free-content">
          <animated.div className="free-image" style={freeSectionStyle}>
            <img src="/assets/zenso_freegift.png" alt="free" />
          </animated.div>
          <animated.div className="free-text" style={freeSectionStyle}>
            <div className='section-title'>
              <span>PROMOTION</span>
            </div>
            <div className='section-header'>
              <span>Limited Free Gifts</span>
            </div>
            <div className='section-subheader'>
              <span>Join our simple, effective program: just chew and drink daily. Follow easy steps each day, and we'll guide you to achieve amazing results in 30 days.</span>
            </div>
            <div className='free-bullet'>
              <div className='bullet-text'>
                <div className='gift-icon-wrapper'><IoGiftOutline className='gift-icon' /></div>
                <span>1-1 weight coaching</span>
              </div>
              <div className='bullet-text'>
                <div className='gift-icon-wrapper'><IoGiftOutline className='gift-icon' /></div>
                <span>Exclusive meal plan</span>
              </div>
              <div className='bullet-text'>
                <div className='gift-icon-wrapper'><IoGiftOutline className='gift-icon' /></div>
                <span>Exclusive ZENSO App  (Track weight, body fat, visceral fat, and more)</span>
              </div>
            </div>
            <button className="cta-btn" onClick={handleCta}>Grab Now</button>
          </animated.div>
        </div>
      </div>
    </section>
  );
}

export default FreeGift;